import type { ILocation, ISiteApis } from '@wix/yoshi-flow-editor';

import type {
  Callback,
  IsSectionInstalledProps,
  MemberInfo,
  RouteConfiguration,
  RouterConfig,
  SectionData,
  ViewerPublicAPI,
} from '../../types';

import { getMembersLoginWidgets } from '../../services/state';

export class PublicAPI
  implements
    Omit<
      ViewerPublicAPI,
      | 'openBlockedMemberEmptyState'
      | 'clearMenus'
      | 'getViewedUser'
      | 'enterPublicProfilePreviewMode'
      | 'leavePublicProfilePreviewMode'
    >
{
  constructor(
    private readonly routes: RouteConfiguration[],
    private readonly settingsRoutes: RouteConfiguration[],
    private readonly location: ILocation,
    private readonly site: ISiteApis,
  ) {}

  async hasSocialPages(onSuccess?: Callback, onError?: Callback) {
    const routes = await this.getRoutes(onSuccess);
    const hasSocialApps = routes.length > 0;

    onSuccess?.(hasSocialApps);

    return Promise.resolve(hasSocialApps);
  }

  async getRoutes(onSuccess?: Callback) {
    onSuccess?.(this.routes);
    return Promise.resolve(this.routes);
  }

  async getSettingsRoutes(onSuccess?: Callback) {
    onSuccess?.(this.settingsRoutes);
    return Promise.resolve(this.settingsRoutes);
  }

  async navigateToSection(sectionData: SectionData, onError?: Callback) {
    throw new Error(
      'Members Area V3: PublicAPI.navigateToSection is not implemented.',
    );
  }

  async navigateToMember(memberInfo: MemberInfo, onError?: Callback) {
    throw new Error(
      'Members Area V3: PublicAPI.navigateToMember is not implemented.',
    );
  }

  async getNavigatableRoles(onError?: Callback) {
    throw new Error(
      'Members Area V3: PublicAPI.getNavigatableRoles is not implemented.',
    );
    return { navigatableMembersRoles: [], isNavigationAllowed: false };
  }

  async getSectionUrl(sectionData: SectionData, onError?: Callback) {
    throw new Error(
      'Members Area V3: PublicAPI.getSectionUrl is not implemented.',
    );
    return '';
  }

  async getMemberPagePrefix(
    data: RouterConfig,
    onSuccess?: Callback,
    onError?: Callback,
  ) {
    throw new Error(
      'Members Area V3: PublicAPI.getMemberPagePrefix is not implemented.',
    );
    return { type: '', prefix: '' };
  }

  setNotificationCount(displayCount: number) {
    const membersLoginWidgets = getMembersLoginWidgets();

    membersLoginWidgets.forEach((widget) => {
      if (widget.navBarItems?.length) {
        widget.navBarItems = [{ ...widget.navBarItems[0], displayCount }];
      }
    });

    return Promise.resolve();
  }

  getIsMembersAreaSeoEnabled() {
    return Promise.resolve(true);
  }

  async isAppSectionInstalled(options: IsSectionInstalledProps) {
    throw new Error(
      'Members Area V3: PublicAPI.isAppSectionInstalled is not implemented.',
    );
    return false;
  }
}
