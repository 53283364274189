import type { CreateControllerFn } from '@wix/yoshi-flow-editor';

export const controllerMAV3: CreateControllerFn = async ({
  controllerConfig,
  appData: controllerAppData,
  flowAPI,
}) => {
  return {
    async pageReady() {},
  };
};

export default controllerMAV3;
