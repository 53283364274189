import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { FlowAPI } from '../../types';
import { controllerCurrentMA } from './current-ma';
import { controllerMAV2 } from './ma-v2';
import {
  isProfilePageBoBInstalled,
  isSettingsPageInstalled,
} from '../../utils/site';

const shouldMountV2Controller = async (flowAPI: FlowAPI) => {
  const [shouldUseV3Context, shouldUseV2Context] = await Promise.all([
    isSettingsPageInstalled(flowAPI.controllerConfig.wixCodeApi),
    isProfilePageBoBInstalled(flowAPI.controllerConfig.wixCodeApi),
  ]);

  return shouldUseV2Context || shouldUseV3Context;
};

const getGlobalController = async (flowAPI: FlowAPI) => {
  const isMembersAreaV2 = await shouldMountV2Controller(flowAPI);
  return isMembersAreaV2 ? controllerMAV2 : controllerCurrentMA;
};

const createController: CreateControllerFn = async (props) => {
  const globalController = await getGlobalController(props.flowAPI);
  return globalController(props);
};

export default createController;
